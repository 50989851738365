:root {
  --nu_red: #D41B2C;
  --brd_grey: gray;
}

.App {
  /* text-align: center; */
  text-align: left;
}

/* .App-logo {
  height: 40vmin;
  height: 10px;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  color: var(--nu_red);
  min-height: 10vh;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  border-bottom: 5px groove var(--brd_grey);
}

.App-title {
  color: black;
}

#nav-dropdown {
  color: var(--nu_red);
}

.NavBar {
  border-right: 5px groove var(--brd_grey);
}

.App-nav-section {
  color: black;
  font-size: calc(10px + 1vmin);
}

.App-nav-item {
  color: var(--nu_red);
  font-size: calc(8px + 1vmin);
  margin-left: 10px;
}

.App-nav-item:hover {
  color: var(--nu_red);
  /* opacity: 0.8; */
  text-decoration: underline;
}

.App-footer {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  border-top: 5px solid black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
