:root {
    --buffer: 10px;
}

.ContentTitle {
    color: black;
    font-size: 30px;
    margin-top: var(--buffer);
    margin-bottom: var(--buffer);
}

.ExecuteQuery {
    margin-top: var(--buffer);
    margin-bottom: var(--buffer);
}

.input-row {
    display: flex;
    align-items: center;
}

/*#nav-dropdown {*/
/*    !*display: flex;*!*/
/*    !*justify-content: right;*!*/
/*    font-size: 100%*/
/*}*/

.App-nav-item {
    /*font-size: 110%;*/
    display: flex;
}

body {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 14px;
}

.ReactTable .rt-thead {
    overflow-y: scroll;
}

/* .inline-label {
    text-align: right;
} */

.form-label {
    font-weight: bold;
    /* background-color: darkgrey; */
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    white-space: pre;
}

.em {
    font-weight: normal;
    font-style: italic;
    white-space: pre;
}